import { ArrowRightOutlined, CalendarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import "moment/locale/tr";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const EventsBar = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [events, setEvents] = useState(null);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getEventsHome().then((data) => {
        setEvents(data.data);
      });
    }
  }, [initialRender]);

  const imajVer = (imajlar) => {
    const imaj =
      imajlar.length > 0 && imajlar.filter((i) => i.isTop === true).pop();
    return imaj === undefined ? false : imaj.url;
  };
  const videoVer = (videolar) => {
    const video =
      videolar.length > 0 && videolar.filter((i) => i.isTop === true).pop();
    return video === undefined ? false : video.url;
  };

  return (
    events && (
      <div>
        <div
          className="relative mt-12   border-t-2 border-warm-300    pt-6 pb-20 px-4 sm:px-6 lg:pt-4 lg:pb-8 lg:px-8  flower-back "
          style={{ backgroundColor: "rgba(216, 202, 186,0.4)" }}
        >
          <div className="absolute inset-0">
            <div
              className=" h-1/3 sm:h-2/3 "
              // style={{ backgroundColor: "rgba(216, 202, 186,0.7)" }}
            />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <div className="text-center">
              <h2 className="text-3xl tracking-tight font-extrabold text-sari mt-4 sm:text-4xl">
                Etkinlikler
              </h2>
            </div>
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {events.map((n) => (
                <div
                  key={n.events.id}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden border"
                >
                  <div className="flex-shrink-0">
                    {imajVer(n.events.images) && (
                      <img
                        className="h-53 w-full object-cover"
                        src={
                          process.env.REACT_APP_FILES_BASEURL +
                          imajVer(n.events.images)
                        }
                        alt=""
                      />
                    )}

                    {!imajVer(n.events.images) && videoVer(n.events.videos) && (
                      <div className="player-wrapper">
                        <ReactPlayer
                          url={videoVer(n.events.videos)}
                          className="react-player"
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      </div>
                    )}
                    {!imajVer(n.events.images) &&
                      !videoVer(n.events.videos) && (
                        <img
                          className="h-52 w-full object-cover"
                          src={require("../../assets/images/news.png").default}
                          alt=""
                        />
                      )}
                  </div>
                  <div
                    className="absolute px-1 m-3 text-sm font-medium text-gray-600   border rounded-md shadow border-gray-400"
                    style={{
                      backgroundColor: moment(n.events.date).isAfter(
                        moment(new Date())
                      )
                        ? "#DAF9A6"
                        : "#FDCBCB",
                    }}
                  >
                    {moment(n.events.date).isAfter(moment(new Date()))
                      ? "YAKLAŞAN ETKİNLİK"
                      : "GEÇMİŞ ETKİNLİK"}
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-sari">
                        <CalendarOutlined />{" "}
                        {moment(n.events.date).format("MMM DD, dddd")}
                      </p>
                      <Link
                        to={`/home/events/${n.events.id}/details`}
                        className="block mt-2"
                      >
                        <p className="text-xl font-semibold text-gray-900">
                          {n.events.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                          {n.events.description}
                        </p>
                      </Link>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <Button
                          onClick={() =>
                            router.push(`/home/events/${n.events.id}/details`)
                          }
                          type="primary"
                          icon={<ArrowRightOutlined />}
                        >
                          Detaylar
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mx-auto text-center mt-10">
              <Button
                type="primary"
                size="large"
                onClick={() => router.push("/home/events")}
                icon={<ArrowRightOutlined />}
              >
                Tüm Etkinlikler
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default EventsBar;
