import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "nprogress/nprogress.css";
import { Router } from "react-router-dom";
import { axHistory } from "./resources/axHistory";
import ScrollToTop from "./resources/scrollTop";
import "moment/locale/tr";
import locale from "antd/lib/locale/tr_TR";
import { ConfigProvider } from "antd";
import { UserContextProvider } from "./context/UserContext";
ReactDOM.render(
  <React.StrictMode>
    <Router history={axHistory}>
      <UserContextProvider>
        <ScrollToTop />
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </UserContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
