import {
  ArrowLeftOutlined,
  ClearOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  notification,
  Form,
  Button,
  Divider,
  Input,
  Select,
  Space,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const ForgetPassword = () => {
  const router = useHistory();
  const [hasCode, setHasCode] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (v) => {
    if (!hasCode) {
      AdminService.forgetPassCode(v.email).then((data) => {
        setHasCode(true);
      });
    } else {
      const obj = {
        code: v.code,
        email: v.email,
        password: v.password,
      };
      AdminService.changePassword(obj).then((data) => {
        notification.success({ message: "Sifre degistirildi" });
        setHasCode(false);
        router.push("/login/loginpage");
      });
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <div className="max-w-md w-full  space-y-8 border border-gray-300 rounded-lg shadow-xl p-4 bg-gray-50 bg-opacity-80">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src={require("../../assets/images/logo.png").default}
            alt="Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-sari">
            Şifre Yenileme Formu
          </h2>
        </div>
        <Form
          className="mt-8 space-y-6"
          onFinish={onFinish}
          form={form}
          onReset={onReset}
          {...GlobalVariables.formLayout}
          style={{ width: "400px", maxWidth: "100%" }}
        >
          <Form.Item
            label="E-Posta adresi"
            name="email"
            rules={[{ required: true, message: "E-Posta gereklidir" }]}
            help={
              hasCode
                ? "Maile Onay kodu gonderildi"
                : "Onay kodu gonderilecektir."
            }
          >
            <Input
              placeholder="E-posta adresinizi yazınız"
              autoComplete="off"
              disabled={hasCode}
              autoCapitalize="off"
            />
          </Form.Item>

          {hasCode && (
            <>
              <Form.Item
                label="Onay Kodu"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Kod gereklidir!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Kodu Yaziniz"
                  style={{ minWidth: "150px" }}
                />
              </Form.Item>
              <Form.Item
                label="Şifreniz"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Şifre gereklidir!",
                  },
                  {
                    pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
                    message:
                      "Şifreniz 6*25 hane olabilir, harf ve rakam icermelidir",
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Yeni şifre" />
              </Form.Item>

              <Form.Item
                label="Şifre Tekrar"
                name="confirm"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Lütfen şifrenizi dogrulayın!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords did not match!");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Lütfen şifrenizi dogrulayın" />
              </Form.Item>
            </>
          )}

          <Form.Item className="justify-end">
            <Button
              onClick={() => router.goBack()}
              icon={<ArrowLeftOutlined />}
            >
              Geri
            </Button>
            <Space>
              <Button htmlType="reset" icon={<ClearOutlined />}>
                Temizle
              </Button>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                {hasCode ? "Kaydet" : "Kod iste"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ForgetPassword;
