import { BookOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { Component, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Slider from "react-slick";
import { AdminService } from "src/resources/AdminService";

export const Okuduklarimiz = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [favoriler, setFavoriler] = useState([]);
  const { lg, md, sm } = useBreakpoint();
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getOkunanlar().then((data) => {
        setFavoriler(data.data);
        console.log(data.data);
      });
    }
  }, [initialRender]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: lg
      ? favoriler.length > 3
        ? 4
        : favoriler.length
      : md
      ? 2
      : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    className: " shadow-lg pb-16 ",
  };
  return (
    favoriler && (
      <div className="my-10">
        <img
          src={require("../../assets/images/oku.png").default}
          alt="one cikanlar"
          className="h-16 lg:h-24 object-contain mx-auto"
        />
        <Slider {...settings}>
          {favoriler.map((f) => (
            <Card
              key={f.id}
              onClick={() => router.push(`/home/publications/${f.id}/details`)}
              bordered={false}
              className="mx-2 cursor-pointer "
            >
              <img
                src={process.env.REACT_APP_FILES_BASEURL + f.banner}
                alt={f.id}
                className="h-64 lg:h-80 object-contain  mb-6 mx-auto rounded shadow-lg"
              />
              <div className="flex align-middle justify-between">
                <span className=" text-sari ">{f.title}</span>
                <span className="text-amazonl font-semibold ">
                  {f.comments} yorum
                </span>
              </div>
              <Card.Meta
                title={
                  <span className="text-lg text-amazonl font-semibold">
                    {f.name}
                  </span>
                }
                description={<span className="text-base">{f.desc}</span>}
              />
              {
                <p className="text-amazonl font-semibold mt-2">
                  {f.comments} yorum
                </p>
              }
            </Card>
          ))}
        </Slider>
        <div style={{ textAlign: "center" }} className="-mt-14">
          <Button
            type="primary"
            size="large"
            icon={<BookOutlined />}
            onClick={() => router.push("/home/publications")}
          >
            Tüm Kitaplar Listesi
          </Button>{" "}
        </div>
      </div>
    )
  );
};
