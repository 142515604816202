import { IdcardOutlined, UserOutlined } from "@ant-design/icons";
import {
  Comment,
  Avatar,
  Form,
  Button,
  List,
  Input,
  notification,
  Space,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { getMyData } from "src/resources/GetUserData";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    size="small"
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "yorum" : "yorum"}`}
    itemLayout="horizontal"
    renderItem={(item) => (
      <li>
        <Comment
          author={item.name}
          avatar={<Avatar size="small" icon={<UserOutlined />} />}
          content={item.comment}
          datetime={moment(item.createdOn).fromNow()}
        />
      </li>
    )}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea
        rows={4}
        onChange={onChange}
        value={value}
        placeholder="Kitap hakkındaki yorumlarınızı paylaşabilirsiniz"
      />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Yorum Ekle
      </Button>
    </Form.Item>
  </>
);
const Comments = ({ commentler, sir, pid }) => {
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const router = useHistory();
  const location = useLocation();
  const handleChange = (v) => {
    setValue(v.target.value);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const obj = {
      comment: value,
      staffId: getMyData.getMyId(),
      publicationId: pid,
    };
    AdminService.newComment(obj).then((data) => {
      notification.success({
        message: "Basarili",
        description: "Yorumunuz kaydedildi",
      });
      sir(true);
      setValue("");
      setSubmitting(false);
    });
  };

  return (
    <>
      {commentler.length > 0 ? (
        <CommentList comments={commentler} />
      ) : (
        <p>İlk yorum yazan siz olun</p>
      )}

      {getMyData.getMyId() ? (
        <Comment
          avatar={<Avatar size="large" icon={<UserOutlined />} />}
          content={
            <Editor
              onChange={handleChange}
              onSubmit={handleSubmit}
              submitting={submitting}
              value={value}
            />
          }
        />
      ) : (
        <div>
          <p>Yorum yazabilmek için üye girişi yapmanız gerekmektedir.</p>
          <Space>
            <Button
              type="primary"
              onClick={() =>
                router.push("/login/loginpage", { from: location })
              }
            >
              Giriş Yap
            </Button>
            <Button onClick={() => router.push("/login/signup")}>Üye ol</Button>
          </Space>
        </div>
      )}
    </>
  );
};
export default Comments;
