import {
  ArrowRightOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  DesktopOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { Button, Col, Image, List, Row, Modal, Tag } from "antd";

import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import RegForm from "./RegForm";

function daysRemaining(date) {
  var eventdate = moment(date);
  var todaysdate = moment();
  return eventdate.diff(todaysdate, "days");
}
const EventBanner = ({ item }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const router = useHistory();
  return (
    <div>
      <Modal
        visible={modalVisible}
        style={{ top: 20 }}
        title={item.title + " etkinliğine kayıt"}
        closable
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <RegForm smv={setModalVisible} eventId={item.id} />
      </Modal>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          xxl={16}
          className="lg:p-6 p-2"
        >
          <p className="font-bold text-2xl lg:text-4xl text-sari  mt-4 mb-2 ">
            Yaklaşan Etkinlik
          </p>
          <p className="font-bold text-2xl text-gray-600   mb-2 ">
            {item.title}
          </p>
          <Image
            preview={false}
            className="gallery-image shadow-lg rounded "
            src={process.env.REACT_APP_FILES_BASEURL + item.images[0].url}
          />
          <p className="mt-4 text-lg text-gray-500">{item.description}</p>
          <Button
            type="primary"
            size="large"
            icon={<ArrowRightOutlined />}
            onClick={() => router.push(`/home/events/${item.id}/details`)}
          >
            Detaylar
          </Button>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} className="lg:pt-14">
          <p className="font-extrabold text-2xl text-sari mx-4 mt-4 mb-0  ">
            Etkinlik Detayları
          </p>
          <div
            className="m-4 px-4 border-2 border-warm-300 shadow-lg rounded  "
            style={{ backgroundColor: "rgba(216, 202, 186,0.4)" }}
          >
            <List
              itemLayout="horizontal"
              bordered={false}
              className="bg-transparent"
            >
              <List.Item></List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<CalendarOutlined className="text-xl" />}
                  title="Etkinlik Zamanı"
                  description={
                    <span className="text-lg text-sari">
                      {moment(item.date).format(" DD MMM , dddd / HH:mm")}
                    </span>
                  }
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<HistoryOutlined className="text-lg" />}
                  title="Kalan Zaman"
                  description={
                    <span>
                      {daysRemaining(item.date) < 3 ? (
                        <Countdown
                          value={moment(item.date)}
                          format="HH:mm:ss"
                        />
                      ) : (
                        <p className="text-lg text-sari">
                          {daysRemaining(item.date) + " gun"}
                        </p>
                      )}
                    </span>
                  }
                />
              </List.Item>
              <List.Item>
                <List.Item.Meta
                  avatar={<CalendarOutlined className="text-xl" />}
                  title="Etkinlik Türü"
                  description={
                    <span className="text-lg text-sari">
                      {item.tip === "online" ? "ONLINE" : "Konumda"}
                    </span>
                  }
                />
              </List.Item>
              {item.tip === "online" && (
                <List.Item>
                  <List.Item.Meta
                    avatar={<DesktopOutlined className="text-xl" />}
                    title="Platformlar & Linkler"
                    description={
                      <>
                        {item.zoom && (
                          <img
                            onClick={() => window.open(item.zoom, "_blank")}
                            className="inline cursor-pointer"
                            src={
                              require("../../assets/images/zoom.png").default
                            }
                            alt=""
                            width="80"
                          />
                        )}
                        {item.meet && (
                          <img
                            onClick={() => window.open(item.meet, "_blank")}
                            className="inline mr-4 cursor-pointer"
                            src={
                              require("../../assets/images/meet.png").default
                            }
                            alt=""
                            width="90"
                          />
                        )}
                        {item.youtube && (
                          <img
                            onClick={() => window.open(item.youtube, "_blank")}
                            className="inline cursor-pointer"
                            src={
                              require("../../assets/images/youtube.png").default
                            }
                            alt=""
                            width="75"
                          />
                        )}
                      </>
                    }
                  />
                </List.Item>
              )}
              {item.tip === "reel" && (
                <List.Item>
                  <List.Item.Meta
                    avatar={<EnvironmentOutlined className="text-xl" />}
                    title="Konum"
                    description={
                      <span className="text-lg">{item.location}</span>
                    }
                  />
                </List.Item>
              )}

              <List.Item>
                <List.Item.Meta
                  avatar={<CheckCircleOutlined className="text-xl" />}
                  title={
                    moment(item.date).isAfter(moment.now()) ? (
                      <Button
                        type="primary"
                        onClick={() => setModalVisible(true)}
                      >
                        Lütfen Kayıt Olun
                      </Button>
                    ) : (
                      <Tag color="red">Kayıt Kapandı</Tag>
                    )
                  }
                />
              </List.Item>
            </List>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EventBanner;
