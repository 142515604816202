import React, { useEffect, useState } from "react";
import { Button, notification, Table } from "antd";
import {
  SortableContainer as sortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import { AdminService } from "src/resources/AdminService";

const Priority = () => {
  const [dataSource, setDataSource] = useState(null);
  useEffect(() => {
    AdminService.getNewsPriority().then((data) => {
      setDataSource(data.data);
    });
  }, []);
  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  const SortableItem = SortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const columns = [
    {
      title: "Sırala",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Başlık",
      dataIndex: "name",
      className: "drag-visible",
    },
  ];
  const saveData = () => {
    const a = [];
    dataSource.forEach((d) => {
      a.push(d.id);
    });
    AdminService.saveNewPriorities(a).then((data) => {
      notification.success({
        message: "Başarılı!",
        description: "Yeni sıralama kaydedildi",
      });
    });
  };
  return (
    <div style={{ maxWidth: "600px" }} className=" p-5 bg-gray-50 border">
      {dataSource && (
        <Table
          className="border"
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      )}
      <br />
      <Button type="primary" onClick={saveData}>
        Kaydet
      </Button>
    </div>
  );
};

export default Priority;
