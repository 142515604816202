import {
  ArrowLeftOutlined,
  ClearOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  notification,
  Form,
  Button,
  Divider,
  Input,
  Select,
  Space,
  Row,
} from "antd";
import React from "react";
import { useHistory } from "react-router";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const SignupPage = () => {
  const router = useHistory();
  const [form] = Form.useForm();
  const onFinish = (v) => {
    AdminService.signup(v).then((data) => {
      notification.success({
        message: "Kaydedildi",
        description: "Lütfen kullanıcı bilgileriniz ile giriş yapınız",
      });
      router.push("/login");
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <div className="max-w-md w-full space-y-8 border border-gray-300 rounded-lg shadow-xl p-4 bg-gray-50 bg-opacity-80">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src={require("../../assets/images/logo.png").default}
            alt="Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-sari">
            Lütfen Kayıt Formunu Doldurunuz
          </h2>
        </div>
        <Form
          className="mt-8 space-y-6"
          onFinish={onFinish}
          form={form}
          onReset={onReset}
          {...GlobalVariables.formLayout}
        >
          <Divider>Yeni Üyelık Formu</Divider>
          <Form.Item
            label="Adınız"
            name="name"
            rules={[{ required: true, message: "Adınız gereklidir" }]}
          >
            <Input placeholder="Lütfen adınızı yazınız" autoComplete="off" />
          </Form.Item>
          <Form.Item
            label="E-Posta adresi"
            name="email"
            rules={[{ required: true, message: "E-Posta gereklidir" }]}
          >
            <Input
              placeholder="E-posta adresinizi yazınız"
              autoComplete="off"
              autoCapitalize="off"
            />
          </Form.Item>

          <Form.Item
            label="Şifreniz"
            name="password"
            rules={[
              {
                required: true,
                message: "Şifre gereklidir!",
              },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,25}$/,
                message:
                  "Şifreniz 6*25 hane olabilir, harf ve rakam icermelidir",
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder="Yeni şifre" />
          </Form.Item>

          <Form.Item
            label="Şifre Tekrar"
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Lütfen şifrenizi dogrulayın!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Passwords did not match!");
                },
              }),
            ]}
          >
            <Input.Password placeholder="Lütfen şifrenizi dogrulayın" />
          </Form.Item>

          <Form.Item className="justify-end">
            <Space>
              <Button
                onClick={() => router.goBack()}
                icon={<ArrowLeftOutlined />}
              >
                Geri
              </Button>
              <Button htmlType="reset" icon={<ClearOutlined />}>
                Temizle
              </Button>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Kaydet
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignupPage;
