import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";

const NewsPage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [news, setNews] = useState(null);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getNewsHome().then((data) => {
        setNews(data.data);
      });
    }
  }, [initialRender]);

  const imajVer = (imajlar) => {
    const imaj =
      imajlar.length > 0 && imajlar.filter((i) => i.isTop === true).pop();
    return imaj === undefined ? false : imaj.url;
  };
  const videoVer = (videolar) => {
    const video =
      videolar.length > 0 && videolar.filter((i) => i.isTop === true).pop();
    return video === undefined ? false : video.url;
  };

  return (
    <div
      className=" bg-gray-50 overflow-hidden lg:py-4"
      style={{ maxWidth: "100%", overflowX: "hidden" }}
    >
      {news &&
        news.map((item) => (
          <div
            key={item.key}
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl mb-20"
          >
            <div className="relative mt-6 sm:mt-6 lg:mt-6">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
                <div className="mt-4 -mx-4 relative lg:mt-0 lg:col-start-1 z-0">
                  {imajVer(item.news.images) && (
                    <img
                      className="relative mx-auto max-h-96 w-auto shadow-xl"
                      src={
                        process.env.REACT_APP_FILES_BASEURL +
                        imajVer(item.news.images)
                      }
                      alt=""
                    />
                  )}

                  {!imajVer(item.news.images) && videoVer(item.news.videos) && (
                    <div className="player-wrapper shadow-xl">
                      <ReactPlayer
                        url={videoVer(item.news.videos)}
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls={true}
                      />
                    </div>
                  )}
                  {!imajVer(item.news.images) &&
                    !videoVer(item.news.videos) && (
                      <img
                        className="relative mx-auto max-h-96 w-auto shadow-xl"
                        src={require("../../assets/images/news.png").default}
                        alt=""
                      />
                    )}
                </div>
                <div className="lg:col-start-2 mt-5">
                  <span className="rounded bg-red-50 border px-2.5 py-1 text-xs font-semibold text-sari tracking-wide uppercase">
                    BLOG
                  </span>{" "}
                  <span className="rounded bg-gray-100 border px-2.5 py-1 text-xs font-semibold text-gray-600 tracking-wide uppercase">
                    {moment(item.news.createdOn).format("MMM DD, dddd")}
                  </span>
                  <h3 className="text-2xl font-extrabold  tracking-tight sm:text-3xl mt-2 text-sari">
                    {item.news.title}
                  </h3>
                  <p className="mt-3 text-lg text-gray-500">
                    {item.news.description}
                  </p>
                  <Button
                    onClick={() =>
                      router.push(`/home/news/${item.news.id}/details`)
                    }
                    type="primary"
                    size="large"
                    icon={<ArrowRightOutlined />}
                  >
                    Detaylar
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default NewsPage;
