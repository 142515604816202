import { ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";

const PublicationItem = ({ item, ay = true, yorum = 0 }) => {
  const router = useHistory();
  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-0">
      <div className=" overflow-hidden sm:pt-12  lg:relative lg:py-36">
        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-4 sm:relative sm:mt-4 sm:py-4 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-100 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full " />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div
              className="relative   sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full cursor-pointer "
              onClick={() =>
                router.push(`/home/publications/${item.id}/details`)
              }
            >
              <img
                className="w-full md:rounded-md shadow ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none "
                src={process.env.REACT_APP_FILES_BASEURL + item.banner}
                alt="banner"
              />
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-5">
              <div>
                <Link
                  to={`/home/publications/${item.id}/details`}
                  className="inline-flex space-x-4"
                >
                  {ay && (
                    <span className="rounded bg-red-50 px-2.5 py-1 text-lg font-semibold text-red-500 border border-red-200 shadow tracking-wide uppercase">
                      AYIN KİTABI
                    </span>
                  )}
                  {/* <span className="inline-flex items-center text-sm font-medium text-sari space-x-1">
                    <span>Get {item.name} PDF</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </span> */}
                </Link>
              </div>
              <div
                className="mt-6 sm:max-w-xl cursor-pointer"
                onClick={() =>
                  router.push(`/home/publications/${item.id}/details`)
                }
              >
                <h1
                  className="text-4xl font-extrabold  tracking-tight sm:text-5xl"
                  style={{ color: "#A18A6D" }}
                >
                  <span className="text-sari">{item.name}</span>
                  <br />
                  {item.title.toUpperCase()}
                </h1>
                <p className="mt-6 text-xl text-gray-500">{item.desc}</p>
                {
                  <p className="text-amazonl font-semibold mt-2">
                    {yorum} yorum
                  </p>
                }
                <Button
                  size="large"
                  type="primary"
                  icon={<ArrowRightOutlined />}
                >
                  Detaylar & Yorumlar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationItem;
