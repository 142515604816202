import {
  ArrowLeftOutlined,
  ClearOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Upload,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { AdminService } from "../../../resources/AdminService";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const NewsEdit = () => {
  // @ts-ignore
  const location = useLocation();
  const router = useHistory();
  // @ts-ignore
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  // @ts-ignore
  const { veri } = location.state || { veri: null };
  const [form] = Form.useForm();
  const [quillValue, setQuillValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const { lg } = useBreakpoint();

  const onFinish = (values) => {
    if (quillValue === "") {
      notification.error({
        message: "Hata!",
        description: "Blog detaylarini yaziniz",
      });
      return;
    }

    const imajlar = [];
    if (fileList.length > 0) {
      if (fileList[0].id !== undefined) {
        fileList[0].url = fileList[0].url.split("\\").pop().split("/").pop();
        imajlar.push({
          id: fileList[0].id,
          url: fileList[0].url,
          isTop: true,
        });
      } else {
        imajlar.push({ url: fileList[0].response.url, isTop: true });
      }
    }
    if (fileList2.length > 0) {
      fileList2.forEach((i) => {
        if (i.id !== undefined) {
          i.url = i.url.split("\\").pop().split("/").pop();
          imajlar.push({ id: i.id, url: i.url, isTop: false });
        } else {
          imajlar.push({ url: i.response.url, isTop: false });
        }
      });
    }

    const req = {
      id: veri.news.id,
      description: values.description,
      title: values.title,
      details: quillValue,
      isActive: true,
      isFavorite: values.isFavorite,
      images: imajlar,
      videos: values.videos,
    };
    AdminService.editNews(req).then((data) => {
      notification.success({
        message: "Basarili!",
        description: "Blog eklendi",
      });
      router.push("/admin/news");
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setFileList2([]);
    quillChange("");
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };

  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      if (veri === null) {
        router.push("/admin/news");
      } else {
        setItem(veri.news);
        setQuillValue(veri.news.details);
        veri.news.images.forEach((imaj) => {
          imaj.url = process.env.REACT_APP_FILES_BASEURL + imaj.url;
        });
        setFileList(veri.news.images.filter((i) => i.isTop === true));
        setFileList2(veri.news.images.filter((i) => i.isTop !== true));
      }
    }
  }, [id, initialRender, router, veri]);
  return (
    item && (
      <div className="overflow-hidden container mx-auto max-w-4xl px-2 py-10">
        <Button
          onClick={() => router.goBack()}
          icon={<ArrowLeftOutlined />}
          type="primary"
          ghost
        >
          Geri dön
        </Button>
        <Form
          layout={!lg ? "vertical" : "horizontal"}
          form={form}
          onFinish={onFinish}
          onReset={onReset}
          initialValues={{ ...veri.news }}
          {...GlobalVariables.formLayout}
        >
          <Form.Item
            name="title"
            label="Başlık"
            rules={[{ required: true, message: "Başlık Gereklidir" }]}
          >
            <Input placeholder="Lütfen başlığı yazınız" />
          </Form.Item>
          <Form.Item
            label="Kısa açıklama"
            name="description"
            rules={[{ required: true, message: "Kısa açıklama gereklidir." }]}
          >
            <Input placeholder="Lütfen kısa açıklama yazınız" />
          </Form.Item>
          <Form.Item
            label="Blog Metni"
            rules={[{ required: true, message: "Blog metni gereklidir" }]}
          >
            <ReactQuill
              modules={modules}
              theme="snow"
              onChange={(e) => quillChange(e)}
              placeholder="Lütfen blog metnini yazınız"
              value={quillValue}
            />
          </Form.Item>
          <Form.Item label="Blog Kapak Resmi">
            <div className="clearfix">
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChangeImage}
                onPreview={handlePreview}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          </Form.Item>
          <Form.Item label="Blog resimleri (Varsa)">
            <>
              <Upload
                action={process.env.REACT_APP_API_BASEURL + "upload"}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                listType="picture-card"
                fileList={fileList2}
                onPreview={handlePreview}
                onChange={handleChange}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </>
          </Form.Item>
          <Form.List name="videos">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "url"]}
                      fieldKey={[fieldKey, "url"]}
                      rules={[{ required: true, message: "Link gerekli" }]}
                    >
                      <Input
                        placeholder="Video linkini yapıştırın"
                        style={{ width: "300px" }}
                      />
                    </Form.Item>

                    <Form.Item
                      valuePropName="checked"
                      name={[name, "isTop"]}
                      fieldKey={[fieldKey, "isTop"]}
                      initialValue={false}
                    >
                      <Checkbox>Ana görsel</Checkbox>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Video Ekle
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item {...GlobalVariables.tailLayout}>
            <Space>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Kaydet
              </Button>
              <Button htmlType="reset" icon={<ClearOutlined />}>
                Temizle
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <br />
      </div>
    )
  );
};

export default NewsEdit;
