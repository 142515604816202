import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Alinti = () => {
  const [alinti, setAlinti] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getRandomExcerpt().then((data) => {
        setAlinti(data.data);
      });
    }
  }, [initialRender]);
  return (
    alinti && (
      <div
        style={{ backgroundColor: "#1c0016", fontFamily: "bookerly" }}
        className="text-center pt-10 -mt-2 pb-10 px-2"
      >
        <p className="text-xl " style={{ color: "#ebc076" }}>
          {alinti.metin}
        </p>
        <p className="text-lg text-gray-50">
          {alinti.yazar.toUpperCase()} / {alinti.kitap}
        </p>
      </div>
    )
  );
};

export default Alinti;
