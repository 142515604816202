import { notification, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Kullanicilar = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [members, setMembers] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getMembers().then((data) => {
        setMembers(data.data);
      });
    }
  }, [initialRender]);
  const isActiveChange = (aktif, id) => {
    AdminService.changeActive({ aktif, id }).then((data) => {
      setInitialRender(true);
      notification.success({ message: "Aktiflik durumu degistirildi" });
    });
  };
  const columns = [
    {
      title: "Ad Soyad",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Yetki",
      dataIndex: "role",
    },
    {
      title: "Aktif mi?",
      render: (item) => (
        <Switch
          defaultChecked={item.isActive}
          onChange={(a) => isActiveChange(a, item.id)}
          disabled={item.role === "Admin"}
        />
      ),
    },
  ];
  return (
    <div className="max-w-4xl">
      <Table
        rowKey="id"
        title={() => "Üye Listesi"}
        loading={members === null}
        columns={columns}
        dataSource={members}
        bordered
        size="small"
      />
    </div>
  );
};

export default Kullanicilar;
