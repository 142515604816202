import {
  EnvironmentOutlined,
  HeartOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Follow } from "react-twitter-widgets";
import { AdminService } from "../../resources/AdminService";

const FooterWidget = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    AdminService.newSubscription(values)
      .then((data) => {
        notification.success({
          message: "Teşekkürler.",
          description: "Üyeliğiniz kaydedildi!",
        });
        setLoading(false);
        form.resetFields();
      })
      .catch((data) => {
        setLoading(false);
      });
  };

  return (
    <div className=" mb-5 ">
      <footer className=" p-8  mt-5 " aria-labelledby="footer-heading">
        <div className="container mx-auto max-w-7xl">
          <Row gutter={[24, 54]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="space-y-8 xl:col-span-1">
                <img
                  className="h-20"
                  src={require("../../assets/images/logo.png").default}
                  alt="footer"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div>
                <h3
                  style={{
                    color: "#D92E2D",
                    fontWeight: 600,
                    borderBottom: "1px solid #ADA293",
                    marginBottom: "25px",
                    lineHeight: "44px",
                  }}
                >
                  İLETİŞİM BİLGİLERİ
                </h3>

                <p style={{ color: "#D92E2D", fontSize: "18px" }}>
                  <MailOutlined
                    style={{ fontSize: "24px", color: "#D92E2D" }}
                  />
                  <a href="mailto://info@aro.org.au" style={{ color: "#333" }}>
                    &nbsp; info@derkenar.com.au
                  </a>
                </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <h3
                style={{
                  color: "#D92E2D",
                  fontWeight: 600,
                  borderBottom: "1px solid #ADA293",
                  marginBottom: "25px",
                  lineHeight: "44px",
                }}
              >
                HABER LİSTEMİZE KAYIT OLUN
              </h3>
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Ad gereklidir" }]}
                >
                  <Input
                    placeholder="Adınız Soyadınız"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email gereklidir" },
                    { type: "email" },
                  ]}
                >
                  <Input
                    placeholder="Email adresiniz"
                    size="large"
                    style={{ maxWidth: "250px" }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Kaydol
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <div className="mt-6 border-t  pt-8" style={{ borderColor: "#ADA293" }}>
          <p className="text-small  text-center text-red-800">
            &copy; 2021 Derkenar Kitap Kulübü . Tüm hakları saklıdır. <br />
            Developed by{" "}
            <Link
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </footer>
    </div>
  );
};

export default FooterWidget;
