import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ForgetPassword from "src/components/login/ForgetPassword";
import LoginPage from "src/components/login/LoginPage";
import SignupPage from "src/components/login/SignpPage";

const LoginLayout = () => {
  return (
    <div
      className="min-h-screen flex items-center justify-center paper-back py-12 px-4 sm:px-6 lg:px-8"
      style={{ backgroundColor: "rgba(240,180,100,.3)" }}
    >
      <Switch>
        <Route path="/login" exact>
          <Redirect to="/login/loginpage" />
        </Route>
        <Route path="/login/loginpage" component={LoginPage} />
        <Route path="/login/signup" component={SignupPage} />
        <Route path="/login/forget" component={ForgetPassword} />

        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
};
export default LoginLayout;
