import { Carousel, Image } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

const SliderBar = ({ slides }) => {
  const router = useHistory();
  return (
    slides &&
    slides.length > 0 && (
      <Carousel autoplay>
        {slides.map((slide) => (
          <div
            key={slide.id}
            onClick={() => router.push(slide.url)}
            className="cursor-pointer"
          >
            <Image
              preview={false}
              src={process.env.REACT_APP_FILES_BASEURL + slide.image}
            />
          </div>
        ))}
      </Carousel>
    )
  );
};
export default SliderBar;
