import { Form, Input, Checkbox, Space, Button, notification } from "antd";

import React, { useState } from "react";
import { AdminService } from "../../resources/AdminService";

const RegForm = ({ smv, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    values = { ...values, eventId: eventId };
    AdminService.newRegisterMember(values)
      .then((data) => {
        notification.success({
          message: "Basarili",
          description: "Kayit yapildi",
        });
        setLoading(false);
        onReset();
        smv(false);
      })
      .catch(() => {
        setLoading(false);
        onReset();
        smv(false);
      });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        layout="vertical"
        initialValues={{ subs: true }}
      >
        <Form.Item
          name="name"
          label="Adınız"
          rules={[{ required: true, message: "Name required" }]}
        >
          <Input placeholder="Adınızı yazınız" />
        </Form.Item>
        <Form.Item
          name="email"
          label="E-mail"
          rules={[{ required: true, message: "E-mail required" }]}
        >
          <Input
            placeholder="Emailinizi yazınız"
            type="email"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item name="phone" label="Telefonunuz">
          <Input placeholder="Telefonunuzu yazınız" />
        </Form.Item>
        <Form.Item name="subs" valuePropName="checked">
          <Checkbox>Beni haber listesine ekle</Checkbox>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              Kaydet
            </Button>
            <Button type="default" htmlType="reset">
              Temizle
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegForm;
