import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  notification,
  PageHeader,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import NewNewsForm from "./NewNewsForm";

const NewsAdmin = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [newsler, setNewsler] = useState(null);
  const [newPanel, setNewPanel] = useState(false);
  const router = useHistory();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getNews().then((data) => {
        setNewsler(data.data);
      });
    }
  }, [initialRender]);

  const activeChanged = (a) => {
    AdminService.changeNewsActive(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Başarılı!",
        description: "Blog güncellendi",
      });
    });
  };

  const favoriteChanged = (a) => {
    AdminService.changeNewsFavorite(a).then((data) => {
      setInitialRender(true);
      notification.success({
        message: "Başarılı!",
        description: "Blog güncellendi",
      });
    });
  };
  const goDetails = (veri) => {
    router.push(`/admin/news/${veri.key}/details`, { veri });
  };
  const goEdit = (veri) => {
    router.push(`/admin/news/${veri.key}/edit`, { veri });
  };
  const deleteNews = (id) => {
    AdminService.deleteNews(id).then((data) => {
      notification.success({
        message: "Başarılı!",
        description: "Blog silindi",
      });
      setInitialRender(true);
    });
  };
  const columns = [
    {
      title: "Başlık",
      dataIndex: "news",
      render: (news) => news.title,
    },
    {
      title: "Kısa Açıklama",
      dataIndex: "news",
      render: (news) => news.description,
    },
    {
      title: "Aktif mi?",
      dataIndex: "news",
      render: (news) => (
        <Switch
          checked={news.isActive}
          onChange={() => activeChanged(news.id)}
        />
      ),
    },
    {
      title: "Favori mi?",
      dataIndex: "news",
      render: (news) => (
        <Switch
          checked={news.isFavorite}
          onChange={() => favoriteChanged(news.id)}
        />
      ),
    },
    {
      title: "İşlemler",
      render: (news) => (
        <Space>
          <Tooltip title="Blog Detayı" placement="left" key={news.id}>
            <Button
              type="primary"
              onClick={() => goDetails(news)}
              icon={<SearchOutlined />}
            />
          </Tooltip>
          <Tooltip title="Güncelle" placement="left" key={news.id}>
            <Button
              type="default"
              onClick={() => goEdit(news)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Blog sil" placement="left" key={news.title}>
            <Popconfirm
              title="Emin misiniz ?"
              onConfirm={() => deleteNews(news.key)}
              okText="Evet! Sil."
              cancelText="İptal"
            >
              <Button type="primary" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <div className="max-w-5xl">
      <PageHeader
        className="site-page-header"
        onBack={() => router.goBack()}
        title={newPanel ? "Yeni blog ekle" : "Bloglar"}
        extra={[
          <Button
            key="a"
            onClick={() => setNewPanel(!newPanel)}
            icon={newPanel ? <CloseOutlined /> : <PlusOutlined />}
            type="primary"
            danger={newPanel}
          >
            {newPanel ? "İptal" : "Yeni Blog Ekle"}
          </Button>,
        ]}
      />
      {!newPanel && (
        <Table
          scroll={{ x: 600 }}
          loading={newsler === null}
          dataSource={newsler}
          bordered
          columns={columns}
          size="small"
        />
      )}
      {newPanel && (
        <NewNewsForm sir={setInitialRender} newPanel={setNewPanel} />
      )}
    </div>
  );
};

export default NewsAdmin;
