import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { AdminService } from "../../resources/AdminService";
import { Button, Space } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import ShareLinks from "./ShareLinks";
import Modal from "antd/lib/modal/Modal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { getMyData } from "src/resources/GetUserData";
import Comments from "./Comments";

const PublicationDetails = () => {
  const { id } = useParams();
  const [comments, setComments] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [previewModal, setPreviewModal] = useState(false);
  const [item, setItem] = useState(null);
  const router = useHistory();
  const { lg } = useBreakpoint();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getPublicationById(id).then((data) => {
        console.log(data.data);
        setItem(data.data);
        setComments(data.data.comments);
      });
    }
  }, [initialRender, id]);
  function createMarkup() {
    return { __html: item.details };
  }

  return (
    <div>
      {item && (
        <>
          <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
            <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-28">
              <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                <div className="py-4 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                  <div className="hidden sm:block">
                    <div className="absolute  left-1/2 w-screen bg-gray-100 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full pb-10" />
                    <svg
                      className="absolute top-3 right-1/2 -mr-3 lg:m-0 lg:left-0"
                      width={404}
                      height={392}
                      fill="none"
                      viewBox="0 0 404 392"
                    >
                      <defs>
                        <pattern
                          id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                          x={0}
                          y={0}
                          width={20}
                          height={20}
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x={0}
                            y={0}
                            width={4}
                            height={4}
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        width={404}
                        height={392}
                        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                      />
                    </svg>
                  </div>
                  <div className="relative   sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full ">
                    <img
                      className="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:w-full lg:max-w-xl"
                      src={process.env.REACT_APP_FILES_BASEURL + item.banner}
                      alt="banner"
                    />
                    <br />
                  </div>
                </div>
              </div>
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                <div className="lg:-my-20">
                  <div>
                    <div className=" sm:max-w-xl">
                      <h1
                        className="text-4xl font-extrabold  tracking-tight sm:text-5xl"
                        style={{ color: "#A18A6D" }}
                      >
                        <span className="text-sari">{item.name}</span>
                        <br />
                        {item.title.toUpperCase()}
                      </h1>
                      <p className="mt-6 text-xl text-gray-500">{item.desc}</p>
                    </div>
                    <div
                      className="booker"
                      dangerouslySetInnerHTML={createMarkup()}
                    />
                    <br />

                    <br />
                    <Comments
                      commentler={comments}
                      sir={setInitialRender}
                      pid={item.id}
                    />

                    <br />

                    <ShareLinks shareUrl={window.location.href} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PublicationDetails;
