import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AboutPage from "../components/about/AboutPage";
import EventDetails from "../components/admin/events/EventDetails";
import NewsDetails from "../components/admin/news/NewsDetails";
import ContactForm from "../components/home/ContactForm";
import EventsPage from "../components/home/EventsPage";
import HomePage from "../components/home/HomePage";
import NewsPage from "../components/home/NewsPage";
import PublicationDetails from "../components/home/PublicationDetails";
import PublicationsPage from "../components/home/PublicationsPage";

const MainMenu = ({ sic, layout }) => {
  return (
    <div className="py-0 px-0  shadow-lg bg-white">
      <Switch>
        <Route path="/home" exact>
          <Redirect to="/home/homepage" />
        </Route>
        <Route path="/home/homepage">
          <HomePage layout={layout} />
        </Route>
        <Route path="/home/about" component={AboutPage} />
        <Route path="/home/events/:id/details" component={EventDetails} />
        <Route path="/home/events" component={EventsPage} />
        <Route path="/home/news/:id/details" component={NewsDetails} />
        <Route path="/home/news" component={NewsPage} />
        <Route path="/home/contact" component={ContactForm} />
        <Route
          path="/home/publications/:id/details"
          component={PublicationDetails}
        />
        <Route path="/home/publications" component={PublicationsPage} />

        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
};

export default MainMenu;
