import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Image,
  Table,
  Upload,
  Popconfirm,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import SliderBar from "./SliderBar";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const SliderAdmin = () => {
  const [initialRender, setInitialRender] = useState(true);
  const [slides, setSlides] = useState(null);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getLayout().then((data) => {
        setSlides(data.data.slides);
      });
    }
  }, [initialRender]);
  const onFinish = (values) => {
    if (fileList.length < 1) {
      notification.error({
        message: "Hata!",
        description: "Slider resmi ekleyin",
      });
      return;
    }

    const req = {
      image: fileList[0].response.url,
      url: values.url,
    };
    AdminService.newSlide(req).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Slide eklendi!",
      });
      form.resetFields();
      setFileList([]);
      setInitialRender(true);
    });
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const deleteSlide = (id) => {
    AdminService.deleteSlide(id).then((data) => {
      notification.success({
        message: "Başarılı",
        description: "Slide silindi!",
      });
    });
    setInitialRender(true);
  };
  const columns = [
    {
      title: "Resim",
      dataIndex: "image",
      render: (image) => (
        <Image
          src={process.env.REACT_APP_FILES_BASEURL + image}
          width="200px"
        />
      ),
    },
    {
      title: "Link",
      dataIndex: "url",
    },
    {
      title: "İşlem",
      render: (item) => (
        <Tooltip title=" Slide sil" placement="left" key={item.id}>
          <Popconfirm
            title="Emin misiniz ?"
            onConfirm={() => deleteSlide(item.id)}
            okText="Evet! Sil."
            cancelText="İptal"
          >
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      ),
    },
  ];
  return (
    <div className="max-w-5xl">
      <h1 className="text-sari text-2xl font-extrabold">Yeni slide ekle</h1>
      <Form form={form} {...GlobalVariables.formLayout} onFinish={onFinish}>
        <Form.Item label="Slide Resmi ">
          <div className="clearfix">
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreview}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
        </Form.Item>
        <Form.Item
          name="url"
          label={`Klik yolu`}
          rules={[{ required: true, message: "Url required" }]}
        >
          <Input placeholder="Sadece yolu yapıştırın --> /news/2/details" />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
      <br />

      <h1 className="text-sari text-2xl font-extrabold">Önizleme</h1>

      <SliderBar slides={slides} />
      <br />
      <h1 className="text-sari text-2xl font-extrabold"> Slide Sil</h1>
      <Table dataSource={slides} columns={columns} size="small" bordered />
    </div>
  );
};

export default SliderAdmin;
