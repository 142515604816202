/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import React from "react";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";
import FooterWidget from "../components/home/FooterWidget";
import { AdminService } from "../resources/AdminService";
import { getMyData } from "src/resources/GetUserData";

const mobileMenu = [
  {
    name: "ANASAYFA",
    href: "/home",
  },
  {
    name: "HAKKIMIZDA",
    href: "/home/about",
  },
  {
    name: "KİTAPLAR",
    href: "/home/publications",
  },
  { name: "ETKİNLİKLER", href: "/home/events" },
  { name: "BLOG", href: "/home/news" },

  {
    name: "İLETİŞİM",
    href: "/home/contact",
  },
];

export default function HomeLayout() {
  const [initialRender, setInitialRender] = useState(true);
  const [layout, setLayout] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getLayout().then((data) => {
        console.log("layout", data.data);
        setLayout(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div
      //style={{ backgroundColor: "rgba(216, 202, 186,0.7)" }}
      className="min-h-screen overflow-x-hidden paper-back"
      style={{ backgroundColor: "rgba(240,180,100,.3)" }}
    >
      <div className="container mx-auto ">
        <Popover className="relative bg-white">
          {({ open }) => (
            <>
              <div className=" mx-auto px-4 sm:px-6   border-gray-300 shadow-md">
                <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
                  <div className="flex justify-start  lg:flex-1">
                    <Link to="/">
                      <span className="sr-only">Workflow</span>
                      <img
                        style={{ width: "250px" }}
                        src={require("../assets/images/logo.png").default}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="-mr-2 -my-2 md:hidden">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sari">
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                  <Popover.Group as="nav" className="hidden md:flex space-x-5">
                    <Link
                      to="/home"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      ANASAYFA
                    </Link>
                    <Link
                      to="/home/about"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      HAKKIMIZDA
                    </Link>
                    <Link
                      to="/home/publications"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      KİTAPLAR
                    </Link>
                    <Link
                      to="/home/events"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      ETKİNLİKLER
                    </Link>

                    <Link
                      to="/home/news"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      BLOG
                    </Link>
                    <Link
                      to="/home/contact"
                      className="text-base font-medium text-gray-700 hover:text-gray-900"
                    >
                      İLETİŞİM
                    </Link>
                  </Popover.Group>
                  <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                    <Link
                      to="/login"
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-sari hover:bg-red-700"
                    >
                      {getMyData.getMyFullname() === null
                        ? "Giriş"
                        : getMyData.getMyFullname()}
                    </Link>
                  </div>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10"
                >
                  {({ close }) => (
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                      <div className="pt-5 pb-6 px-5">
                        <div className="flex items-center justify-between">
                          <div>
                            <img
                              className="h-12 w-auto"
                              src={require("../assets/images/logo.png").default}
                              alt="logo"
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sari">
                              <span className="sr-only">Close menu</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                      <div className="py-6 px-5 space-y-6">
                        <div className="grid grid-cols-1 gap-y-6 gap-x-8">
                          {mobileMenu.map((item) => (
                            <Link
                              onClick={() => close()}
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                            >
                              <span className="ml-3 text-base font-medium text-gray-900">
                                {item.name}
                              </span>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>

        <MainMenu sic={null} layout={layout} />
        <FooterWidget />
        <br />
      </div>
    </div>
  );
}
