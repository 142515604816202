import { axios } from "./HttpClient";

export const AdminService = {
  newPublication: (values) => {
    return axios.post("/publications", values);
  },
  getPublications: () => {
    return axios.get("/publications");
  },
  getFavoritePublications: () => {
    return axios.get("/publications/favorites");
  },
  getPublicationById: (id) => {
    return axios.get("/publications/" + id);
  },
  getLayout: () => {
    return axios.get("/layout");
  },
  newNews: (values) => {
    return axios.post("/news", { ...values });
  },
  editNews: (values) => {
    return axios.put("/news/" + values.id, { ...values });
  },
  getNews: () => {
    return axios.get("/news");
  },
  getNewsHome: () => {
    return axios.get("/news/home");
  },
  deleteNews: (id) => {
    return axios.delete("/news/" + id);
  },
  changeNewsFavorite: (id) => {
    return axios.put("/news/favorite/" + id);
  },
  changeNewsActive: (id) => {
    return axios.put("/news/active/" + id);
  },
  getDashboard: () => {
    return axios.get("/layout/dashboard");
  },
  login: (values) => {
    return axios.post("/staff/login", { ...values });
  },
  getFavoriteNews: () => {
    return axios.get("/news/favorite");
  },
  getNewsById: (id) => {
    return axios.get("/news/" + id);
  },
  newEvent: (values) => {
    return axios.post("/events", { ...values });
  },
  getEvents: () => {
    return axios.get("/events");
  },
  editEvents: (values) => {
    return axios.put("/events/" + values.id, { ...values });
  },
  getEventById: (id) => {
    return axios.get("/events/" + id);
  },
  newRegisterMember: (values) => {
    return axios.post("/registers", { ...values });
  },
  deleteRegistrations: (id) => {
    return axios.delete("/registers/" + id);
  },
  getEventsList: () => {
    return axios.get("/events/list");
  },
  getEventRegs: (id) => {
    return axios.get("/events/" + id + "/registrations");
  },
  newSubscription: (values) => {
    return axios.post("/subscriptions", { ...values });
  },
  getSubscriptions: () => {
    return axios.get("/subscriptions");
  },
  deleteSubscriptions: (id) => {
    return axios.delete("/subscriptions/" + id);
  },
  getEventsHome: () => {
    return axios.get("/events/home");
  },
  newContact: (values) => {
    return axios.post("/contacts", { ...values });
  },
  getContacts: () => {
    return axios.get("/contacts");
  },
  changeIsOk: (id) => {
    return axios.put("/contacts/isok/" + id);
  },
  sendEmailToRegs: (values) => {
    return axios.post("/registers/sendemail", { ...values });
  },
  getLogin: (values) => {
    return axios.post("/staff/login", { ...values });
  },
  deleteEvent: (id) => {
    return axios.delete("/events/" + id);
  },
  changeEventFavorite: (id) => {
    return axios.put("/events/favorite/" + id);
  },
  changeEventActive: (id) => {
    return axios.put("/events/active/" + id);
  },
  changePubsFavorite: (id) => {
    return axios.put("/publications/favorite/" + id);
  },
  changePubsActive: (id) => {
    return axios.put("/publications/active/" + id);
  },
  changePubsBanner: (id) => {
    return axios.put("/publications/banner/" + id);
  },
  deletePubs: (id) => {
    return axios.delete("/publications/" + id);
  },
  newSlide: (values) => {
    return axios.post("/layout/slide", { ...values });
  },
  deleteSlide: (id) => {
    return axios.delete("/layout/slide/" + id);
  },
  getNewsPriority: () => {
    return axios.get("/news/priority");
  },
  saveNewPriorities: (value) => {
    return axios.post("/news/priorities", { sira: value });
  },
  getEventsPriority: () => {
    return axios.get("/events/priority");
  },
  saveEventPriorities: (value) => {
    return axios.post("/events/priorities", { sira: value });
  },
  newExcerpt: (values) => {
    return axios.post("/excerpt", values);
  },
  getRandomExcerpt: () => {
    return axios.get("/excerpt/random");
  },
  getExcerpts: () => {
    return axios.get("/excerpt");
  },
  signup: (values) => {
    return axios.post("/staff/signup", values);
  },
  newComment: (values) => {
    return axios.post("/comments", values);
  },
  forgetPassCode: (email) => {
    return axios.get("/staff/getforgetcode?email=" + email);
  },
  changePassword: (values) => {
    return axios.post("/staff/changepass", values);
  },
  getOkunanlar: () => {
    return axios.get("/publications/okunanlar");
  },
  getMembers: () => {
    return axios.get("/staff");
  },
  changeActive: (values) => {
    return axios.put("/staff/active", values);
  },
};
