var jwt = require("jsonwebtoken");
var decoded;
var refreshData = () => {
  var token = localStorage.getItem("token") || null;
  decoded =
    token !== null ? jwt.decode(token, { complete: true }).payload : null;
};
export const getMyData = {
  getMyId: function () {
    refreshData();
    console.log("GEctim");
    return decoded !== null ? decoded.primarysid : null;
  },
  getMyEmail: function () {
    refreshData();
    return decoded !== null ? decoded.email : null;
  },
  getMyRole: function () {
    refreshData();
    return decoded !== null ? decoded.role : null;
  },
  getMyFullname: function () {
    refreshData();
    return decoded !== null ? decoded.unique_name : null;
  },
};
export const getInitialUserData = {
  getFullData: function () {
    refreshData();
    return {
      myId: decoded !== null ? decoded.primarysid : null,
      myEmail: decoded !== null ? decoded.email : null,
      myRole: decoded !== null ? decoded.role : null,
      myFullname: decoded !== null ? decoded.unique_name : null,
    };
  },
};
