import {
  PlusOutlined,
  MinusCircleOutlined,
  SaveOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Switch,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import moment from "moment";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { AdminService } from "../../../resources/AdminService";
import { GlobalVariables } from "../../../resources/GlobalVariables";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const NewEventsForm = ({ sir, newPanel }) => {
  const [form] = useForm();
  const [quillValue, setQuillValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [platformlar, setPlatformlar] = useState([]);
  const [tipim, setTipim] = useState("online");
  const { lg } = useBreakpoint();
  const { Option } = Select;

  const onFinish = (values) => {
    if (fileList.length === 0) {
      notification.error({
        message: "Hata!",
        description: "Kapak resmi eklemelisiniz",
      });
      return;
    }

    if (quillValue === "") {
      notification.error({
        message: "Hata!",
        description: "Detay yazmalısınız",
      });
      return;
    }

    const imajlar = [];

    imajlar.push({ url: fileList[0].response.url, isTop: true });
    if (fileList2.length > 0) {
      fileList2.forEach((i) => {
        imajlar.push({ url: i.response.url, isTop: false });
      });
    }

    const req = {
      description: values.short,
      title: values.title,
      details: quillValue,
      isActive: true,
      isFavorite: values.isFavorite,
      images: imajlar,
      videos: values.videos,
      date: moment(values.date).format(),
      meet: values.meet,
      youtube: values.youtube,
      zoom: values.zoom,
      tip: values.tip,
    };
    AdminService.newEvent(req).then((data) => {
      notification.success({
        message: "Başarılı!",
        description: "Etkinlik eklendi.",
      });
      newPanel(false);
      sir(true);
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setFileList2([]);
    quillChange("");
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  const switchChange = (a) => {
    setIsFavorite(a);
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const changeTip = (tip) => {
    setTipim(tip);
  };
  const platformChange = (tipler) => {
    //  setTipim(tip);
    setPlatformlar(tipler);
  };
  return (
    <div className="max-w-5xl">
      <Form
        layout={!lg ? "vertical" : "horizontal"}
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        initialValues={{ videos: [], isFavorite: false, tip: "online" }}
        {...GlobalVariables.formLayout}
      >
        <Form.Item name="tip" label="Event Type">
          <Select onChange={changeTip}>
            <Select.Option key="reel" value="reel">
              Reel Etkinlik
            </Select.Option>
            <Select.Option key="online" value="online">
              Online Etkinlik
            </Select.Option>
          </Select>
        </Form.Item>
        {tipim === "reel" && (
          <Form.Item name="location" label="Etkinlik Yeri">
            <Input placeholder="Etkilniğin yapılacağı yeri yazınız" />
          </Form.Item>
        )}
        {tipim === "online" && (
          <Form.Item
            name="platforms"
            label="Etkinlik Platformu"
            rules={[
              {
                required: true,
                message: "Platformları seçiniz!",
                type: "array",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Etkinlik platformlarını seçimiz"
              onChange={platformChange}
            >
              <Option value="zoom">Zoom Etkinliği</Option>
              <Option value="youtube">Youtube Live</Option>
              <Option value="meet">Google Meet</Option>
            </Select>
          </Form.Item>
        )}
        {platformlar.findIndex((p) => p === "zoom") !== -1 && (
          <Form.Item label="Zoom Link" name="zoom">
            <Input placeholder="Zoom linkini yapıştırın" />
          </Form.Item>
        )}
        {platformlar.findIndex((p) => p === "youtube") !== -1 && (
          <Form.Item label="Youtube Live Link" name="youtube">
            <Input placeholder="Youtube linkini yapıştırın" />
          </Form.Item>
        )}
        {platformlar.findIndex((p) => p === "meet") !== -1 && (
          <Form.Item label="Meet Link" name="meet">
            <Input placeholder="Meet linkini yapıştırın" />
          </Form.Item>
        )}
        <Form.Item
          name="date"
          label="Etkinlik zamanı"
          rules={[
            { type: "object", required: true, message: "Zaman gereklidir" },
          ]}
        >
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            placeholder="Zamanı seçiniz"
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Etkinlik başlığı"
          rules={[{ required: true, message: "Başlık gereklidir" }]}
        >
          <Input placeholder="Başlığı yazınız" />
        </Form.Item>
        <Form.Item
          label="Kısa Açıklama"
          name="short"
          rules={[{ required: true, message: "Kısa açıklama gereklidir." }]}
        >
          <Input placeholder="Kısa açıklama yazınız" />
        </Form.Item>
        <Form.Item
          label="Detaylar"
          rules={[{ required: true, message: "Detaylar gereklidir" }]}
        >
          <ReactQuill
            modules={modules}
            theme="snow"
            onChange={(e) => quillChange(e)}
            placeholder="Etkinlikle ilgili detayları paylaşınız"
          />
        </Form.Item>
        <Form.Item label="Banner Resmi (gerekli)">
          <div className="clearfix">
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreview}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
        </Form.Item>
        <Form.Item label="Galeri resimleri">
          <>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList2}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </Form.Item>
        <Form.List name="videos">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "url"]}
                    fieldKey={[fieldKey, "url"]}
                    rules={[{ required: true, message: "link gerekli" }]}
                  >
                    <Input
                      placeholder="PVideo Linkini yapıştırın"
                      style={{ width: "300px" }}
                    />
                  </Form.Item>

                  <Form.Item
                    valuePropName="checked"
                    name={[name, "isTop"]}
                    fieldKey={[fieldKey, "isTop"]}
                    initialValue={false}
                  >
                    <Checkbox>Ana görsel</Checkbox>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Video Ekle
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item label="Favori mi ?" name="isFavorite">
          <Switch checked={isFavorite} onChange={switchChange} />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              Kaydet
            </Button>
            <Button htmlType="reset" icon={<ClearOutlined />}>
              Temizle
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewEventsForm;
