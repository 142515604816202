import React, { useEffect, useState } from "react";
import { Button, Col, Layout, Menu, Row, Tag } from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  CommentOutlined,
  DashboardOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  HeartOutlined,
  LoginOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  UserOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  Link,
  useHistory,
} from "react-router-dom";
import AdminPage from "../components/admin/AdminPage";
import Publication from "../components/admin/publication/Publication";
import NewsDetails from "../components/admin/news/NewsDetails";
import NewsAdmin from "../components/admin/news/NewsAdmin";
import EventsAdmin from "../components/admin/events/EventsAdmin";
import EventDetails from "../components/admin/events/EventDetails";
import Registrations from "../components/admin/events/Registrations";
import Subscriptions from "../components/admin/Subscriptions";
import Contacts from "../components/admin/Contacts";
import SliderAdmin from "src/components/admin/banner/SliderAdmin";
import Priority from "src/components/admin/news/Priority";
import PriorityEvents from "src/components/admin/events/PriorityEvents";
import NewsEdit from "src/components/admin/news/NewsEdit";
import EventEdit from "src/components/admin/events/EventEdit";
import Excerpt from "../components/admin/Excerpt";
import Kullanicilar from "src/components/admin/Kullanicilar";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg, sm } = useBreakpoint();
  const [isSider, setIsSider] = useState(false);
  const location = useLocation();
  const router = useHistory();
  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        style={{
          height: "75px",
          lineHeight: "75px",
          position: lg ? "fixed" : "unset",
          zIndex: 1,
          width: "100%",
          backgroundColor: "white",
          borderBottom: "1px solid #ccc",
          boxShadow: "1px 1px 10px #ddd",
        }}
      >
        <Row justify={!sm ? "start" : "space-between"} align="middle">
          <Col>
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          </Col>
          <Col>
            <img
              onClick={() => router.push("/home/homepage")}
              src={require("../assets/images/logo.png").default}
              alt="logo"
              style={{
                maxWidth: "180px",
                marginLeft: !sm ? "20px" : "",
                cursor: "pointer",
              }}
            />
          </Col>
          {lg && (
            <Col>
              <Button
                onClick={() => {
                  localStorage.clear();
                  router.push("/home");
                }}
                type="primary"
                icon={<LogoutOutlined />}
              >
                Log Out
              </Button>
            </Col>
          )}
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <br />
          <Tag
            color="blue"
            style={{
              width: "calc(100% - 40px)",
              textAlign: "center",
              margin: "5px 20px 0 20px",
            }}
          >
            Admin Menu
          </Tag>
          <Menu
            className="amenu"
            onSelect={() => {
              !lg && setIsSider(true);
            }}
            theme="light"
            mode="inline"
            defaultSelectedKeys={[location.pathname.split("/").pop()]}
          >
            <Menu.Item key="adminpage" icon={<DashboardOutlined />}>
              <Link to="/admin/adminpage">Pano</Link>
            </Menu.Item>
            <Menu.Item key="publications" icon={<FilePdfOutlined />}>
              <Link to="/admin/publications">Kitaplar</Link>
            </Menu.Item>
            <Menu.Item key="members" icon={<UserOutlined />}>
              <Link to="/admin/members">Üyeler</Link>
            </Menu.Item>
            <Menu.Item key="news" icon={<NotificationOutlined />}>
              <Link to="/admin/news">Bloglar</Link>
            </Menu.Item>
            <Menu.Item
              key="newspriority"
              icon={<VerticalAlignMiddleOutlined />}
            >
              <Link to="/admin/news/priority">Blog Sıralaması</Link>
            </Menu.Item>
            <Menu.Item key="events" icon={<CalendarOutlined />}>
              <Link to="/admin/events">Etkinlikler</Link>
            </Menu.Item>
            <Menu.Item
              key="eventspriority"
              icon={<VerticalAlignMiddleOutlined />}
            >
              <Link to="/admin/events/priority">Etkinlik Sıralaması</Link>
            </Menu.Item>
            <Menu.Item key="slider" icon={<FileImageOutlined />}>
              <Link to="/admin/slider">Slider</Link>
            </Menu.Item>
            <Menu.Item key="excerpt" icon={<CommentOutlined />}>
              <Link to="/admin/excerpt">Alıntılar</Link>
            </Menu.Item>
            <Menu.Item key="registrations" icon={<CheckCircleOutlined />}>
              <Link to="/admin/registrations">Etkinlik Kayıtları</Link>
            </Menu.Item>
            <Menu.Item key="subscriptions" icon={<LoginOutlined />}>
              <Link to="/admin/subscriptions">Mail Listesi</Link>
            </Menu.Item>
            <Menu.Item key="contacts" icon={<MailOutlined />}>
              <Link to="/admin/contacts">İletişim</Link>
            </Menu.Item>
            {/* <Menu.Item key="contacts" icon={<MessageOutlined />}>
              <Link to="/staff/contacts">Contacts</Link>
            </Menu.Item>
            <Menu.Item key="newsletters" icon={<MailOutlined />}>
              <Link to="/staff/newsletters">Newsletters</Link>
            </Menu.Item>
      
            <Menu.Item key="forms" icon={<FormOutlined />}>
              <Link to="/staff/forms">Forms</Link>
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "75px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
            minHeight: "calc(100vh - 150px)",
          }}
        >
          <Switch>
            <Route path="/admin" exact>
              <Redirect to="/admin/adminpage" />
            </Route>
            <Route path="/admin/adminpage" component={AdminPage} />
            <Route path="/admin/publications" component={Publication} />
            <Route path="/admin/news/:id/details" component={NewsDetails} />
            <Route path="/admin/news/:id/edit" component={NewsEdit} />
            <Route path="/admin/news/priority" component={Priority} />
            <Route path="/admin/news" component={NewsAdmin} />
            <Route path="/admin/events/:id/details" component={EventDetails} />
            <Route path="/admin/events/:id/edit" component={EventEdit} />
            <Route path="/admin/events/priority" component={PriorityEvents} />
            <Route path="/admin/events" component={EventsAdmin} />
            <Route path="/admin/contacts" component={Contacts} />
            <Route path="/admin/registrations" component={Registrations} />
            <Route path="/admin/subscriptions" component={Subscriptions} />
            <Route path="/admin/slider" component={SliderAdmin} />
            <Route path="/admin/excerpt" component={Excerpt} />
            <Route path="/admin/members" component={Kullanicilar} />

            {/* <Route path="/staff/contacts" component={Contacts} />
            <Route path="/staff/newsletters" component={Newsletters} />
            <Route path="/staff/forms" component={Forms} />
           */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer className="footer">
        <div>
          <p className="text-small text-gray-400 text-center">
            &copy; 2021 Derkenar Kitap Kulübü . Tüm hakları saklıdır. <br />
            Developed by{" "}
            <Link
              to={{ pathname: "https://softmile.com.au" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Softmile &nbsp;
            </Link>
            with <HeartOutlined /> React
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default AdminLayout;
