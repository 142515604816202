import { Button, Col, Form, Input, notification, Row, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { AdminService } from "../../resources/AdminService";

const ContactForm = () => {
  const [form] = useForm();

  const harita2 = ` <iframe width="100%" height="600" frameborder="0" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=7%20speed%20street%20liverpool%20nsw%202170&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed";"></iframe>
  `;

  const onFinish = (v) => {
    AdminService.newContact(v).then((data) => {
      notification.success({
        message: "Success",
        description: "Message sent!",
      });
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="container mx-auto overflow-x-hidden md:p-4 p-2 max-w-6xl">
      <div>
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-700 sm:text-4xl">
          Contact with AUF
        </h3>
      </div>
      <Row gutter={[40, 40]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <>
            <br />
            <h2 className="text-base text-sari font-semibold tracking-wide uppercase">
              İLETİŞİM BİLGİLERİMİZ
            </h2>
            <div
              className=" relative flex flex-wrap py-6   max-w-5xl  "
              style={{
                border: "1px solid #ddd",
                boxShadow: "0 0 5px #eee",
                backgroundColor: "#fafafa",
              }}
            >
              <div className="lg:w-1/2 px-6 leading-relaxed">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADRES
                </h2>
                <p className="mt-1">
                  7 Speed Street
                  <br />
                  Liverpool NSW 2170 Australia
                </p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a
                  href="mailto://info@auf.net.au"
                  className="text-blue-500 leading-relaxed"
                >
                  info@auf.net.au
                </a>
                <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  TELEFON
                </h2>
                <p>+61 02 9822 4448</p>
              </div>
            </div>
            <br />
            <br />
            <h2 className="text-base text-sari font-semibold tracking-wide uppercase">
              İLETİŞİM FORMU
            </h2>
            <div
              style={{
                border: "1px solid #ddd",
                boxShadow: "0 0 5px #eee",
                padding: "20px",
                backgroundColor: "#fafafa",
              }}
            >
              <Form form={form} onFinish={onFinish} onReset={onReset}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Ad Gereklidir" }]}
                >
                  <Input placeholder="Adınız" />
                </Form.Item>

                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Email gereklidir" },
                    { type: "email", message: "Gecersiz Email adresi" },
                  ]}
                >
                  <Input
                    placeholder="Email Adresiniz"
                    type="email"
                    autoComplete="email"
                  />
                </Form.Item>

                <Form.Item
                  name="subject"
                  rules={[{ required: true, message: "Konu Gereklidir" }]}
                >
                  <Input placeholder="Konu Başlığı" />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: "Mesaj gereklidir" }]}
                >
                  <TextArea rows={4} placeholder="Mesajınız" />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Gönder
                    </Button>
                    <Button type="primary" ghost htmlType="reset">
                      Temizle
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          </>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <>
            <br />

            <h2 className="text-base text-sari font-semibold tracking-wide uppercase">
              AUF KONUMU
            </h2>
            <div
              style={{
                border: "2px solid #ddd",
                boxShadow: "0 0 5px #eee",
              }}
              dangerouslySetInnerHTML={{ __html: harita2 }}
            />
          </>
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </div>
  );
};

export default ContactForm;
