import { LockClosedIcon } from "@heroicons/react/solid";
import { Button, Form, Space } from "antd";
import React, { useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { UserContext } from "src/context/UserContext";
import { AdminService } from "src/resources/AdminService";
import { getMyData } from "src/resources/GetUserData";

const LoginPage = () => {
  const router = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  const { setUserValues } = useContext(UserContext);
  const onFinish = (v) => {
    AdminService.login(v).then((data) => {
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      const userValues = {
        myId: getMyData.getMyId(),
        myEmail: getMyData.getMyEmail(),
        myRole: getMyData.getMyRole(),
        myFullname: getMyData.getMyFullname(),
      };
      setUserValues(userValues);
      data.data.user.role === "Admin"
        ? router.push("/admin")
        : router.push(from);
    });
  };
  useEffect(() => {
    localStorage.clear();
  }, []);
  return (
    <div>
      <div className="max-w-md w-full space-y-8 border border-gray-300 rounded-lg shadow-xl p-4 bg-gray-50 bg-opacity-80">
        <div>
          <img
            onClick={() => router.push("/")}
            className="mx-auto h-20 w-auto cursor-pointer"
            src={require("../../assets/images/logo.png").default}
            alt="Logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-sari">
            Lütfen Giriş Yapınız
          </h2>
        </div>
        <Form className="mt-8 space-y-6" onFinish={onFinish}>
          <Form.Item name="email">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email adresi
              </label>
              <input
                id="email-address"
                name="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-sari focus:border-sari focus:z-10 sm:text-sm"
                placeholder="Email adresi"
              />
            </div>
          </Form.Item>
          <Form.Item name="password">
            <div className="-mt-10">
              <label htmlFor="password" className="sr-only">
                Şifre
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-sari focus:border-sari focus:z-10 sm:text-sm"
                placeholder="Şifre"
              />
            </div>
          </Form.Item>

          {/* <div className="flex items-center justify-between">
    <div className="flex items-center">
      <input
        id="remember-me"
        name="remember-me"
        type="checkbox"
        className="h-4 w-4 text-sari focus:ring-sari border-gray-300 rounded"
      />
      <label
        htmlFor="remember-me"
        className="ml-2 block text-sm text-gray-900"
      >
        Remember me
      </label>
    </div>

    <div className="text-sm">
      <Link
        to="#"
        className="font-medium text-sari hover:text-sari"
      >
        Forgot your password?
      </Link>
    </div>
  </div> */}

          <Space className="w-full" direction="vertical">
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-sari hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sari"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon
                  className="h-5 w-5 text-red-300 group-hover:text-red-400"
                  aria-hidden="true"
                />
              </span>
              Giriş
            </button>
            <div className="flex justify-between align-middle">
              <span>
                Üye değil misiniz?<Link to="/login/signup"> Üye olun</Link>
              </span>
              <Link to="/login/forget"> Şifremi unuttum!</Link>
            </div>
            <Button
              onClick={() => router.push("/")}
              block
              style={{ borderRadius: "8px", height: "37px" }}
            >
              Ana sayfa
            </Button>
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
