import {
  ArrowRightOutlined,
  ClearOutlined,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  List,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { getMyData } from "src/resources/GetUserData";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Excerpt = () => {
  const [form] = Form.useForm();
  const [alintilar, setAlintilar] = useState(null);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getExcerpts().then((data) => {
        setAlintilar(data.data);
      });
    }
  }, [initialRender]);
  const onReset = () => {
    form.resetFields();
  };
  const onFinish = (values) => {
    const id = getMyData.getMyId();
    values = { ...values, staffId: id };
    AdminService.newExcerpt(values).then((data) => {
      notification.success({
        message: "Kaydedildi!",
        description: "Alıntı başarı ile kaydedildi",
      });
      onReset();
      setInitialRender(true);
    });
  };

  const deleteExcerpt = (id) => {
    console.log(id);
  };
  return (
    <div className="container mx-auto">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left">Kaydedilmiş Alıntılar</Divider>
          {alintilar && (
            <List
              className="demo-loadmore-list"
              loading={alintilar === null}
              itemLayout="horizontal"
              dataSource={alintilar}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Tooltip title="Delete Project" placement="left">
                      <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => deleteExcerpt(item.id)}
                        okText="Yes! Delete."
                        cancelText="Cancel"
                      >
                        <Button
                          type="primary"
                          danger
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Tooltip>,
                  ]}
                >
                  <Skeleton avatar title={false} loading={item.loading} active>
                    <List.Item.Meta
                      avatar={<ArrowRightOutlined />}
                      title={item.yazar + " / " + item.kitap}
                      description={item.metin}
                    />
                  </Skeleton>
                </List.Item>
              )}
            />
          )}
        </Col>
        <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
          <Divider orientation="left"> Yeni alıntı ekle</Divider>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            {...GlobalVariables.formLayout}
          >
            <Form.Item
              label="Alıntı Metni"
              name="metin"
              rules={[{ required: true, message: "Alinti metni gereklidir!" }]}
            >
              <Input.TextArea placeholder="Lütfen alıntı metnini yazınız" />
            </Form.Item>
            <Form.Item
              label="Yazar"
              name="yazar"
              rules={[{ required: true, message: "Alinti yazari gereklidir!" }]}
            >
              <Input placeholder="Lütfen alıntı metnini yazınız" />
            </Form.Item>
            <Form.Item
              label="Kitap"
              name="kitap"
              rules={[
                { required: true, message: "Alinti hangi kaynaktan alindi!" },
              ]}
            >
              <Input placeholder="Lütfen alıntı metnini yazınız" />
            </Form.Item>
            <Form.Item {...GlobalVariables.tailLayout}>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Kaydet
                </Button>
                <Button htmlType="reset" icon={<ClearOutlined />}>
                  Temizle
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Excerpt;
