import { Affix, Spin } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import SliderBar from "../admin/banner/SliderBar";
import AboutBar from "./AboutBar";
import Alinti from "./Alinti";
import EventBanner from "./EventBanner";
import EventsBar from "./EventsBar";
import { KitapSlider } from "./KitapSlider";
import NewsBar from "./NewsBar";
import { Okuduklarimiz } from "./Okuduklarimiz";
import PublicationItem from "./PublicationItem";
import SocialBanner from "./SocialBanner";

const HomePage = ({ layout }) => {
  const { sm } = useBreakpoint();
  return (
    <div>
      <Spin spinning={layout === null}>
        {layout && layout.slides.length > 0 && (
          <div>
            <SliderBar slides={layout.slides} />
          </div>
        )}
        <Alinti />
        {layout && layout.publication.isThere && (
          <PublicationItem
            item={layout.publication.data}
            yorum={layout.publication.data.comments}
          />
        )}
        <Okuduklarimiz />
        <KitapSlider />
        {layout && layout.events.isThere && (
          <EventBanner item={layout.events.data} />
        )}
      </Spin>
      <br />
      <NewsBar />
      <AboutBar />
      <EventsBar />

      {/* <SocialBanner /> */}
    </div>
  );
};

export default HomePage;
